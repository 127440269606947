<template>
  <v-dialog v-model="dialog" width="1000">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="!list"
        elevation="0"
        v-on="on"
        block
        height="30px"
        :disabled="disabled"
        v-text="$t('edit', { name: '' })"
      />
    </template>
    <v-card id="Commissions">
      <v-card-title
        style="text-decoration: underline; text-transform: uppercase"
      >
        {{ $t("tattooers.studio_tattooer_commissions") }}
      </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <form
        action
        data-vv-scope="comisions-form"
        @submit.prevent="validateForm('comisions-form')"
      >
        <v-card-text v-if="tattooer">
          <v-row justify="center">
            <v-col cols="12" class=""></v-col>
            <v-col
              cols="12"
              class="py-0"
              v-if="
                (tattooer.tattooer_studios[0].comisions &&
                  tattooer.tattooer_studios[0].comisions.type) ||
                !view
              "
            >
              <ComisionForm
                :disabled="disable"
                class="ma-1"
                ref="comisionForm"
                :commissions="tattooer.tattooer_studios[0].comisions"
              ></ComisionForm>
            </v-col>
            <v-col v-else class="white--text text-center"
              >No configuradas por el Estudio</v-col
            >
          </v-row>
          <!--
            <base-material-alert
            v-if="$store.getters['auth/getSetting']('comision_type') !== type"
            color="login_error"
            dark
          >
            Este Artista está utilizando un sistema de comisiones diferente al
            actual.
            <a @click="updateComisionSistem()">Clique aquí</a> para
            actualizarlo.
          </base-material-alert>-->
        </v-card-text>
        <v-card-actions v-if="!disable">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="dialog = false"
            style="height: 30px; width: 100px"
            elevation="0"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            style="
              width: 100px !important;
              color: #363533;
              height: 30px;
              padding-top: 10px !important;
            "
            elevation="0"
          >
            <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: "" }) }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "EditCommisions",
  props: ["tattooer", "view", "disable", "list"],
  mounted() {
    console.log("montando", this.tattooer);

    if (this.view) {
      console.log("view");
      this.fetchCommissions();
    } else if (!this.tattooer.tattooer_studios[0].comisions) {
      console.log("view2");
      //console.log("SETTING COMMISION");
      this.tattooer.tattooer_studios[0].comisions = {
        type: null,
        price: null,
        comisions: [],
      };
    }

    //this.type = this.tattooer.tattooer_studios[0].comisions.type;
    //console.log(this.tattooer.tattooer_studios[0].comisions);
    //console.log(this.type);
  },
  data() {
    return {
      dialog: false,
      type: null,
    };
  },
  components: {
    ComisionForm: () => import("@/components/tattooer/CommissionsForm"),
  },
  methods: {
    updateComisionSistem() {
      this.type = this.$store.getters["auth/getSetting"]("comision_type");
      //console.log(this.type);
      this.$nextTick(() => {
        if (
          this.$store.getters["auth/getSetting"]("comision_has_definition") ===
          1
        ) {
          this.tattooer.tattooer_studios[0].comisions = JSON.parse(
            this.$store.getters["auth/getSetting"]("commisions_predeterminated")
          );
        } else {
          this.$refs.comisionForm.changeComisions();
        }
      });
      //console.log(this.tattooer.tattooer_studios[0].comisions);
    },

    ...mapActions("tattooers", ["updateCommissions", "getCommissions"]),
    fetchCommissions() {
      console.log("test", this.tattooer);
      if (this.tattooer)
        this.getCommissions(this.tattooer.id).then((data) => {
          if (data.data) {
            if (data.data.comisions && data.data.comisions.type !== null)
              this.tattooer.tattooer_studios[0].comisions = data.data.comisions;

            console.log("com", this.tattooer.tattooer_studios[0].comisions);
          }
        });
      else console.log("NO TATTOOER");
    },
    async validateForm(scope) {
      console.log("VALIDANDO");
      this.errors.clear();
      this.userProcessed = false;
      let val_money_1 = this.$refs.money_1
        ? await this.$refs.money_1.validate()
        : true;
      let val_money_2 = this.$refs.money_2
        ? await this.$refs.money_2.validate()
        : true;
      let result = await this.$validator.validateAll(scope);
      let rs = await this.$refs.comisionForm.validate();
      console.log(result, val_money_1, val_money_2, rs);
      if (result && val_money_1 && val_money_2 && rs) {
        this.updateCommissions({
          tattooer_studios: this.tattooer.tattooer_studios[0],
        }).then((result) => {
          console.log(result.success);
          if (result.success) {
            this.dialog = false;
            this.$emit("update");
            this.$alert(this.$t("save_ok"));
          }
        });
      }
    },
  },
};
</script>
<style lang="sass">
#Commissions
</style>